export const numberFormats = {
  en: {
    currency: {
      style: 'currency',
      currency: 'USD',
      currencyDisplay: 'symbol'
    },
    currencyWithoutDecimal: {
      style: 'currency',
      currency: 'USD',
      currencyDisplay: 'symbol',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    },
    integer: {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    },
    percent: {
      style: 'percent',
      useGrouping: false
    }
  },
  fr: {
    currency: {
      style: 'currency',
      currency: 'EUR',
      currencyDisplay: 'symbol'
    },
    currencyWithoutDecimal: {
      style: 'currency',
      currency: 'EUR',
      currencyDisplay: 'symbol',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    },
    integer: {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    },
    percent: {
      style: 'percent',
      useGrouping: false,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }
  },
  es: {
    currency: {
      style: 'currency',
      currency: 'EUR',
      currencyDisplay: 'symbol'
    },
    currencyWithoutDecimal: {
      style: 'currency',
      currency: 'EUR',
      currencyDisplay: 'symbol',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    },
    integer: {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    },
    percent: {
      style: 'percent',
      useGrouping: false,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }
  },
  de: {
    currency: {
      style: 'currency',
      currency: 'EUR',
      currencyDisplay: 'symbol'
    },
    currencyWithoutDecimal: {
      style: 'currency',
      currency: 'EUR',
      currencyDisplay: 'symbol',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    },
    integer: {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    },
    percent: {
      style: 'percent',
      useGrouping: false,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }
  },
  nl: {
    currency: {
      style: 'currency',
      currency: 'EUR',
      currencyDisplay: 'symbol'
    },
    currencyWithoutDecimal: {
      style: 'currency',
      currency: 'EUR',
      currencyDisplay: 'symbol',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    },
    integer: {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    },
    percent: {
      style: 'percent',
      useGrouping: false,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }
  },
  pl: {
    currency: {
      style: 'currency',
      currency: 'PLN',
      currencyDisplay: 'symbol'
    },
    currencyWithoutDecimal: {
      style: 'currency',
      currency: 'PLN',
      currencyDisplay: 'symbol',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    },
    integer: {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    },
    percent: {
      style: 'percent',
      useGrouping: false,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }
  }
} as const
